import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: () => import('../../components/views/Publik/Beranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../../components/views/Publik/Login.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KontakKami',
    name: 'KontakKami',
    component: () => import('../../components/views/Publik/KontakKami.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/GIS/:id',
    name: 'GIS',
    component: () => import('../../components/views/Publik/GIS.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarRegulasi',
    name: 'DaftarRegulasi',
    component: () => import('../../components/views/Publik/DaftarRegulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../../components/views/Dashboard/Dashboard.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Slider',
    name: 'Slider',
    component: () => import('../../components/views/Backoffice/Slider/Slider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSlider/:id',
    name: 'ProsesSlider',
    component: () => import('../../components/views/Backoffice/Slider/ProsesSlider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InfoBeranda',
    name: 'InfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/InfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesInfoBeranda/:id',
    name: 'ProsesInfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Regulasi',
    name: 'Regulasi',
    component: () => import('../../components/views/Backoffice/Regulasi/Regulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesRegulasi/:id',
    name: 'ProsesRegulasi',
    component: () => import('../../components/views/Backoffice/Regulasi/ProsesRegulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KategoriData',
    name: 'KategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/KategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKategoriData/:id',
    name: 'ProsesKategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/ProsesKategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/LayerPeta',
    name: 'LayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/LayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesLayerPeta/:id',
    name: 'ProsesLayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/User',
    name: 'User',
    component: () => import('../../components/views/Backoffice/User/User.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesUser/:id',
    name: 'ProsesUser',
    component: () => import('../../components/views/Backoffice/User/ProsesUser.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/SettingData',
    name: 'SettingData',
    component: () => import('../../components/views/Backoffice/SettingData/SettingData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/PBRSS',
    name: 'PBRSS',
    component: () => import('../../components/views/Backoffice/PBRSS/PBRSS.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPBRSS/:id',
    name: 'ProsesPBRSS',
    component: () => import('../../components/views/Backoffice/PBRSS/ProsesPBRSS.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ViewPBRSS/:id',
    name: 'ViewPBRSS',
    component: () => import('../../components/views/Backoffice/PBRSS/ViewPBRSS.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/PBRRP',
    name: 'PBRRP',
    component: () => import('../../components/views/Backoffice/PBRRP/PBRRP.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPBRRP/:id',
    name: 'ProsesPBRRP',
    component: () => import('../../components/views/Backoffice/PBRRP/ProsesPBRRP.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ViewPBRRP/:id',
    name: 'ViewPBRRP',
    component: () => import('../../components/views/Backoffice/PBRRP/ViewPBRRP.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/PBRTKB',
    name: 'PBRTKB',
    component: () => import('../../components/views/Backoffice/PBRTKB/PBRTKB.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPBRTKB/:id',
    name: 'ProsesPBRTKB',
    component: () => import('../../components/views/Backoffice/PBRTKB/ProsesPBRTKB.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ViewPBRTKB/:id',
    name: 'ViewPBRTKB',
    component: () => import('../../components/views/Backoffice/PBRTKB/ViewPBRTKB.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/PBPKRTKB',
    name: 'PBPKRTKB',
    component: () => import('../../components/views/Backoffice/PBPKRTKB/PBPKRTKB.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPBPKRTKB/:id',
    name: 'ProsesPBPKRTKB',
    component: () => import('../../components/views/Backoffice/PBPKRTKB/ProsesPBPKRTKB.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ViewPBPKRTKB/:id',
    name: 'ViewPBPKRTKB',
    component: () => import('../../components/views/Backoffice/PBPKRTKB/ViewPBPKRTKB.vue'),
    meta: {
      transition: "zoom",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
})

export default router;
