<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <center>
            <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
                aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
                style="margin: auto;">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Lampiran</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p>
                            <div class="col-sm-12">
                                <iframe src="" id="iframefile" style="width: 100%;height: 55vh;"></iframe>
                            </div>
                            </p>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-danger btn-sm lebar" data-dismiss="modal"><i
                                    class="fas fa-window-close"></i> Tutup</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- /.modal -->
        </center>
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-4 wow fadeInDown">
                            <h5>View Data</h5>
                        </div>
                        <div class="col-sm-8 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/PBPKRTKB">Peningkatan Kualitas Rumah Korban /
                                        Terdampak
                                        Bencana</a>
                                </li>
                                <li class="breadcrumb-item active">Data</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih2">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Data Peningkatan Kualitas Rumah Korban / Terdampak Bencana
                                    </h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <fieldset class="scheduler-border">
                                        <legend class="scheduler-border">Data Penerima</legend>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label class="labelgray">Tahun Data</label>
                                                <v-select :options="tahun" :reduce="(label) => label.code" label="label"
                                                    v-model="defaultSelectedtahun" @update:modelValue="ontahunChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kab./Kota</label>
                                                <v-select :options="kabkot" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedkabkot"
                                                    @update:modelValue="onkabkotChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kecamatan</label>
                                                <v-select :options="kecamatan" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedkecamatan"
                                                    @update:modelValue="onkecamatanChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Desa / Kelurahan</label>
                                                <v-select :options="desa" :reduce="(label) => label.code" label="label"
                                                    v-model="defaultSelecteddesa" @update:modelValue="ondesaChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Nama</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm" id="nama"
                                                        name="nama" placeholder="Nama" v-model="nama"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">NIK</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm" id="nik"
                                                        name="nik" placeholder="NIK" v-model="nik"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Nomor KK</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm" id="nokk"
                                                        name="nokk" placeholder="Nomor KK" v-model="nokk"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">ID BDT</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm" id="nobdt"
                                                        name="nobdt" placeholder="ID BDT" v-model="nobdt"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Usia</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm" id="usia"
                                                        name="usia" placeholder="Usia" v-model="usia"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Status Perkawinan</label>
                                                <v-select :options="statusnikah" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedstatusnikah"
                                                    @update:modelValue="onstatusnikahChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Anggota Keluarga (Orang)</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm"
                                                        id="anggota_keluarga" name="anggota_keluarga"
                                                        placeholder="Anggota Keluarga (Orang)"
                                                        v-model="anggota_keluarga" style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Status Bantuan</label>
                                                <v-select :options="statusbantuan" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedstatusbantuan"
                                                    @update:modelValue="onstatusbantuanChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-12">
                                                <label class="labelgray">Alamat</label>
                                                <quill-editor v-model:value="alamat"
                                                    style="height: 10vh;background-color: #eef1f6;color: #111;"
                                                    v-bind:class="{ disabled: true }"></quill-editor>
                                            </div>
                                            <div class="col-sm-3">
                                                <label class="labelgray">RT</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm" id="rt"
                                                        name="rt" placeholder="RT" v-model="rt"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <label class="labelgray">RW</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm" id="rw"
                                                        name="rw" placeholder="RW" v-model="rw"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Dukuh</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm" id="dukuh"
                                                        name="dukuh" placeholder="Dukuh" v-model="dukuh"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Jenis Bencana</label>
                                                <v-select :options="jenis_bencana" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedjenis_bencana"
                                                    @update:modelValue="onjenis_bencanaChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Tahun Kejadian Bencana</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm"
                                                        id="tahunbencana" name="tahunbencana"
                                                        placeholder="Tahun Kejadian Bencana" v-model="tahunbencana"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Pekerjaan</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm"
                                                        id="pekerjaan" name="pekerjaan" placeholder="Pekerjaan"
                                                        v-model="pekerjaan" style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Penghasilan</label>
                                                <v-select :options="penghasilan" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedpenghasilan"
                                                    @update:modelValue="onpenghasilanChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Bersedia Swadaya</label>
                                                <v-select :options="kesediaan_swadaya" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedkesediaan_swadaya"
                                                    @update:modelValue="onkesediaan_swadayaChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Jumlah Swadaya</label>
                                                <v-select :options="jumlah_swadaya" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedjumlah_swadaya"
                                                    @update:modelValue="onjumlah_swadayaChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-12">&nbsp;</div>

                                        </div>
                                    </fieldset>
                                    <fieldset class="scheduler-border2">
                                        <legend class="scheduler-border2">Data Rumah</legend>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label class="labelgray">Status Tinggal</label>
                                                <v-select :options="status_tinggal" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedstatus_tinggal"
                                                    @update:modelValue="onstatus_tinggalChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Status Perolehan</label>
                                                <v-select :options="status_perolehan" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedstatus_perolehan"
                                                    @update:modelValue="onstatus_perolehanChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Panjang Bangunan (m)</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm"
                                                        id="panjang_bangunan" name="panjang_bangunan"
                                                        placeholder="Panjang Bangunan (m)" v-model="panjang_bangunan"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Lebar Bangunan (m)</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm"
                                                        id="lebar_bangunan" name="lebar_bangunan"
                                                        placeholder="Lebar Bangunan (m)" v-model="lebar_bangunan"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kerusakan Rangka Atap</label>
                                                <v-select :options="atap" :reduce="(label) => label.code" label="label"
                                                    v-model="defaultSelectedatap" @update:modelValue="onatapChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kerusakan Balok</label>
                                                <v-select :options="balok" :reduce="(label) => label.code" label="label"
                                                    v-model="defaultSelectedbalok" @update:modelValue="onbalokChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kerusakan Kolom</label>
                                                <v-select :options="kolom" :reduce="(label) => label.code" label="label"
                                                    v-model="defaultSelectedkolom" @update:modelValue="onkolomChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kerusakan Pondasi</label>
                                                <v-select :options="pondasi" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedpondasi"
                                                    @update:modelValue="onpondasiChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kerusakan Dinding</label>
                                                <v-select :options="dinding" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelecteddinding"
                                                    @update:modelValue="ondindingChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kerusakan Genteng</label>
                                                <v-select :options="genteng" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedgenteng"
                                                    @update:modelValue="ongentengChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kerusakan Plafon</label>
                                                <v-select :options="plafon" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedplafon"
                                                    @update:modelValue="onplafonChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kerusakan Lantai</label>
                                                <v-select :options="lantai" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedlantai"
                                                    @update:modelValue="onlantaiChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kerusakan Pintu</label>
                                                <v-select :options="pintu" :reduce="(label) => label.code" label="label"
                                                    v-model="defaultSelectedpintu" @update:modelValue="onpintuChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kerusakan Jendela</label>
                                                <v-select :options="jendela" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedjendela"
                                                    @update:modelValue="onjendelaChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-4" style="display: none;">
                                                <label class="labelgray">Struktur Roboh</label>
                                                <v-select :options="struktur_roboh" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedstruktur_roboh"
                                                    @update:modelValue="onstruktur_robohChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-4" style="display: none;">
                                                <label class="labelgray">Non Struktur Roboh</label>
                                                <v-select :options="nonstruktur_roboh" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectednonstruktur_roboh"
                                                    @update:modelValue="onnonstruktur_robohChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Tingkat Kerusakan</label>
                                                <v-select :options="tingkat_kerusakan" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedtingkat_kerusakan"
                                                    @update:modelValue="ontingkat_kerusakanChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                        </div>
                                        <div class="row" style="display: none;">
                                            <div class="col-sm-6">
                                                <label class="labelgray">Nama Pemilik</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm"
                                                        id="nama_pemilik" name="nama_pemilik" placeholder="Nama Pemilik"
                                                        v-model="nama_pemilik" style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Nomor KK Pemilik</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm"
                                                        id="nokk_pemilik" name="nokk_pemilik"
                                                        placeholder="Nomor KK Pemilik" v-model="nokk_pemilik"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label class="labelgray">Alamat Pemilik</label>
                                                <quill-editor v-model:value="alamat_pemilik"
                                                    style="height: 10vh;background-color: #eef1f6;color: #111;"
                                                    v-bind:class="{ disabled: true }"></quill-editor>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Status Tinggal</label>
                                                <v-select :options="status_tinggal" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedstatus_tinggal"
                                                    @update:modelValue="onstatus_tinggalChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Hubungan Dengan</label>
                                                <v-select :options="hubungan" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedhubungan"
                                                    @update:modelValue="onhubunganChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Jumlah Penghuni Rumah (Orang)</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm"
                                                        id="jumlah_penghuni" name="jumlah_penghuni"
                                                        placeholder="Jumlah Penghuni Rumah (Orang)"
                                                        v-model="jumlah_penghuni" style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Jumlah KK Penghuni Rumah (KK)</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm"
                                                        id="jumlah_kk" name="jumlah_kk"
                                                        placeholder="Jumlah KK Penghuni Rumah (KK)" v-model="jumlah_kk"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="scheduler-border">
                                        <legend class="scheduler-border">Data Lahan</legend>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <label class="labelgray">Alamat Lahan</label>
                                                <quill-editor v-model:value="alamat_lahan"
                                                    style="height: 10vh;background-color: #eef1f6;color: #111;"
                                                    v-bind:class="{ disabled: true }"></quill-editor>
                                            </div>
                                            <div class="col-sm-6" style="display: none;">
                                                <label class="labelgray">Status Perolehan</label>
                                                <v-select :options="status_perolehan" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedstatus_perolehan"
                                                    @update:modelValue="onstatus_perolehanChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Kondisi Lahan</label>
                                                <v-select :options="kondisi_lahan" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedkondisi_lahan"
                                                    @update:modelValue="onkondisi_lahanChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Sertifikat Lahan</label>
                                                <v-select :options="sertifikat_lahan" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedsertifikat_lahan"
                                                    @update:modelValue="onsertifikat_lahanChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Panjang Lahan (m)</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm"
                                                        id="panjang_lahan" name="panjang_lahan"
                                                        placeholder="Panjang Lahan (m)" v-model="panjang_lahan"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Lebar Lahan (m)</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm"
                                                        id="lebar_lahan" name="lebar_lahan"
                                                        placeholder="Lebar Lahan (m)" v-model="lebar_lahan"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="scheduler-border2">
                                        <legend class="scheduler-border2">Lampiran</legend>
                                        <div class="row">
                                            <!--IKON KTP 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">KTP Penerima Bantuan<small class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_ktp != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_ktp, 'parastapainnovation_attachment-KTP')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_ktp" name="fileToUploadfilenya_ktp"
                                                        placeholder="fileToUpload" style="font-size: smaller;"
                                                        accept="image/*" disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_ktp" name="filenya_ktp" placeholder="File KTP">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_ktp">
                                                                <span id="tulisanpersenfilenya_ktp">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_ktp"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_ktp', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_ktp')"
                                                            disabled="disabled">Hapus File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON KTP 1 END-->

                                            <!--IKON ATAP 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Foto Rangka Atap<small class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_atap != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_atap, 'parastapainnovation_attachment-Atap')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_atap" name="fileToUploadfilenya_atap"
                                                        placeholder="fileToUpload" style="font-size: smaller;"
                                                        accept="image/*" disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_atap" name="filenya_atap" placeholder="File Atap">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_atap">
                                                                <span id="tulisanpersenfilenya_atap">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_atap"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_atap', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_atap')"
                                                            disabled="disabled">Hapus File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON ATAP 1 END-->

                                            <!--IKON BALOK 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Foto Balok<small class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_balok != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_balok, 'parastapainnovation_attachment-Balok')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_balok" name="fileToUploadfilenya_balok"
                                                        placeholder="fileToUpload" style="font-size: smaller;"
                                                        accept="image/*" disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_balok" name="filenya_balok"
                                                        placeholder="File Balok">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_balok">
                                                                <span id="tulisanpersenfilenya_balok">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_balok"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_balok', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_balok')"
                                                            disabled="disabled">Hapus File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON BALOK 1 END-->

                                            <!--IKON KOLOM 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Foto Kolom<small class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_kolom != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_kolom, 'parastapainnovation_attachment-Kolom')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_kolom" name="fileToUploadfilenya_kolom"
                                                        placeholder="fileToUpload" style="font-size: smaller;"
                                                        accept="image/*" disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_kolom" name="filenya_kolom"
                                                        placeholder="File Kolom">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_kolom">
                                                                <span id="tulisanpersenfilenya_kolom">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_kolom"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_kolom', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_kolom')"
                                                            disabled="disabled">Hapus File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON KOLOM 1 END-->

                                            <!--IKON PONDASI 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Foto Pondasi<small class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_pondasi != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_pondasi, 'parastapainnovation_attachment-Pondasi')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_pondasi"
                                                        name="fileToUploadfilenya_pondasi" placeholder="fileToUpload"
                                                        style="font-size: smaller;" accept="image/*"
                                                        disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_pondasi" name="filenya_pondasi"
                                                        placeholder="File Pondasi">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_pondasi">
                                                                <span id="tulisanpersenfilenya_pondasi">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_pondasi"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_pondasi', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_pondasi')"
                                                            disabled="disabled">Hapus
                                                            File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON PONDASI 1 END-->

                                            <!--IKON Dinding 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Foto Dinding<small class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_dinding != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_dinding, 'parastapainnovation_attachment-Dinding')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_dinding"
                                                        name="fileToUploadfilenya_dinding" placeholder="fileToUpload"
                                                        style="font-size: smaller;" accept="image/*"
                                                        disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_dinding" name="filenya_dinding"
                                                        placeholder="File Dinding">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_dinding">
                                                                <span id="tulisanpersenfilenya_dinding">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_dinding"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_dinding', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_dinding')"
                                                            disabled="disabled">Hapus
                                                            File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON Dinding 1 END-->

                                            <!--IKON Genteng 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Foto Genteng<small class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_genteng != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_genteng, 'parastapainnovation_attachment-Genteng')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_genteng"
                                                        name="fileToUploadfilenya_genteng" placeholder="fileToUpload"
                                                        style="font-size: smaller;" accept="image/*"
                                                        disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_genteng" name="filenya_genteng"
                                                        placeholder="File Genteng">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_genteng">
                                                                <span id="tulisanpersenfilenya_genteng">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_genteng"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_genteng', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_genteng')"
                                                            disabled="disabled">Hapus
                                                            File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON Genteng 1 END-->

                                            <!--IKON Plafon 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Foto Plafon<small class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_plafon != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_plafon, 'parastapainnovation_attachment-Plafon')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_plafon"
                                                        name="fileToUploadfilenya_plafon" placeholder="fileToUpload"
                                                        style="font-size: smaller;" accept="image/*"
                                                        disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_plafon" name="filenya_plafon"
                                                        placeholder="File Plafon">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_plafon">
                                                                <span id="tulisanpersenfilenya_plafon">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_plafon"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_plafon', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_plafon')"
                                                            disabled="disabled">Hapus File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON Plafon 1 END-->

                                            <!--IKON Lantai 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Foto Lantai<small class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_lantai != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_lantai, 'parastapainnovation_attachment-Lantai')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_lantai"
                                                        name="fileToUploadfilenya_lantai" placeholder="fileToUpload"
                                                        style="font-size: smaller;" accept="image/*"
                                                        disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_lantai" name="filenya_lantai"
                                                        placeholder="File Lantai">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_lantai">
                                                                <span id="tulisanpersenfilenya_lantai">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_lantai"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_lantai', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_lantai')"
                                                            disabled="disabled">Hapus File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON Lantai 1 END-->

                                            <!--IKON Pintu 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Foto Pintu<small class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_pintu != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_pintu, 'parastapainnovation_attachment-Pintu')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_pintu" name="fileToUploadfilenya_pintu"
                                                        placeholder="fileToUpload" style="font-size: smaller;"
                                                        accept="image/*" disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_pintu" name="filenya_pintu"
                                                        placeholder="File Pintu">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_pintu">
                                                                <span id="tulisanpersenfilenya_pintu">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_pintu"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_pintu', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_pintu')"
                                                            disabled="disabled">Hapus File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON Pintu 1 END-->

                                            <!--IKON Jendela 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Foto Jendela<small class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_jendela != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_jendela, 'parastapainnovation_attachment-Jendela')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_jendela"
                                                        name="fileToUploadfilenya_jendela" placeholder="fileToUpload"
                                                        style="font-size: smaller;" accept="image/*"
                                                        disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_jendela" name="filenya_jendela"
                                                        placeholder="File Jendela">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_jendela">
                                                                <span id="tulisanpersenfilenya_jendela">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_jendela"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_jendela', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_jendela')"
                                                            disabled="disabled">Hapus
                                                            File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON Jendela 1 END-->

                                            <!--IKON KK PENERIMA 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Rumah 0 % Tampak Depan<small class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_kk_penerima != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_kk_penerima, 'parastapainnovation_attachment-KKPenerima')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_kk_penerima"
                                                        name="fileToUploadfilenya_kk_penerima"
                                                        placeholder="fileToUpload" style="font-size: smaller;"
                                                        accept="image/*" disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_kk_penerima" name="filenya_kk_penerima"
                                                        placeholder="File KK">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_kk_penerima">
                                                                <span id="tulisanpersenfilenya_kk_penerima">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success"
                                                            id="suksesnyafilenya_kk_penerima"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_kk_penerima', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_kk_penerima')"
                                                            disabled="disabled">Hapus
                                                            File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON KK PENERIMA 1 END-->
                                            <div class="col-sm-12">&nbsp;</div>

                                            <!--IKON KK PEMILIK LAHAN 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Rumah 0 % Samping Kanan 45 <sup>o</sup><small
                                                        class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_kk_pemilik != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_kk_pemilik, 'parastapainnovation_attachment-KKPemilik')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_kk_pemilik"
                                                        name="fileToUploadfilenya_kk_pemilik" placeholder="fileToUpload"
                                                        style="font-size: smaller;" accept="image/*"
                                                        disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_kk_pemilik" name="filenya_kk_pemilik"
                                                        placeholder="File KK">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_kk_pemilik">
                                                                <span id="tulisanpersenfilenya_kk_pemilik">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success"
                                                            id="suksesnyafilenya_kk_pemilik"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_kk_pemilik', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_kk_pemilik')"
                                                            disabled="disabled">Hapus
                                                            File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON KK PEMILIK LAHAN 1 END-->
                                            <div class="col-sm-12">&nbsp;</div>

                                            <!--IKON SERTIFIKAT 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Rumah 0 % Samping Kiri 45 <sup>o</sup><small
                                                        class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_lahan != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_lahan, 'parastapainnovation_attachment-SertifikatLahan')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_lahan" name="fileToUploadfilenya_lahan"
                                                        placeholder="fileToUpload" style="font-size: smaller;"
                                                        accept="image/*" disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_lahan" name="filenya_lahan"
                                                        placeholder="File Sertifikat">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_lahan">
                                                                <span id="tulisanpersenfilenya_lahan">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_lahan"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_lahan', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_lahan')"
                                                            disabled="disabled">Hapus File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON SERTIFIKAT 1 END-->
                                            <div class="col-sm-12">&nbsp;</div>

                                            <!--IKON Foto Calon Penerima 1-->
                                            <div class="col-sm-12">
                                                <label class="labelgray">Foto Dengan Tim Verval Di Depan Rumah<small
                                                        class="text-info"> (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_penerima_rumah != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_penerima_rumah, 'parastapainnovation_attachment-PenerimaRumah')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_penerima_rumah"
                                                        name="fileToUploadfilenya_penerima_rumah"
                                                        placeholder="fileToUpload" style="font-size: smaller;"
                                                        accept="image/*" disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_penerima_rumah" name="filenya_penerima_rumah"
                                                        placeholder="File Sertifikat">
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_penerima_rumah">
                                                                <span id="tulisanpersenfilenya_penerima_rumah">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success"
                                                            id="suksesnyafilenya_penerima_rumah"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_penerima_rumah', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_penerima_rumah')"
                                                            disabled="disabled">Hapus
                                                            File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON Foto Calon Penerima 1 END-->
                                            <div class="col-sm-12">&nbsp;</div>

                                            <!--IKON Foto Calon Penerima 2-->
                                            <div class="col-sm-12" style="display: none;">
                                                <label class="labelgray">Foto Lahan 0 % Tampak Depan<small
                                                        class="text-info"> (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_penerima_lahan != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_penerima_lahan, 'parastapainnovation_attachment-PenerimaLahan')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_penerima_lahan"
                                                        name="fileToUploadfilenya_penerima_lahan"
                                                        placeholder="fileToUpload" style="font-size: smaller;"
                                                        accept="image/*" disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_penerima_lahan" name="filenya_penerima_lahan"
                                                        placeholder="File Sertifikat">
                                                </div>
                                            </div>
                                            <div class="col-sm-12" style="display: none;">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_penerima_lahan">
                                                                <span id="tulisanpersenfilenya_penerima_lahan">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success"
                                                            id="suksesnyafilenya_penerima_lahan"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_penerima_lahan', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_penerima_lahan')"
                                                            disabled="disabled">Hapus
                                                            File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON Foto Calon Penerima 2 END-->
                                            <div class="col-sm-12" style="display: none;">&nbsp;</div>

                                            <!--IKON DENAH-->
                                            <div class="col-sm-12" style="display: none;">
                                                <label class="labelgray">Foto Dengan Tim Verval Di Depan Lahan<small
                                                        class="text-info">
                                                        (*jpg)</small>
                                                </label>
                                                <label v-if="filenya_sketsa != ''" style="float: right;"> <a
                                                        href="javascript:void(0)"
                                                        @click="lihatlampiran(filenya_sketsa, 'parastapainnovation_attachment-Sketsa')"><i
                                                            class="fas fa-file-image"></i> Lihat
                                                        Lampiran</a></label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="far fa-file-image"></i></span>
                                                    </div>
                                                    <input type="file" class="form-control form-control-sm"
                                                        id="fileToUploadfilenya_sketsa"
                                                        name="fileToUploadfilenya_sketsa" placeholder="fileToUpload"
                                                        style="font-size: smaller;" accept="image/*"
                                                        disabled="disabled">
                                                    <input type="hidden" class="form-control form-control-sm"
                                                        id="filenya_sketsa" name="filenya_sketsa"
                                                        placeholder="File Sertifikat">
                                                </div>
                                            </div>
                                            <div class="col-sm-12" style="display: none;">
                                                <label>Opsi</label>
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar"
                                                                aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                                                                style="width:0%" id="persennyafilenya_sketsa">
                                                                <span id="tulisanpersenfilenya_sketsa">0%</span>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <span class="text-success" id="suksesnyafilenya_sketsa"></span>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <button type="button"
                                                            class="btn btn-warning btn-sm lebar text-white"
                                                            @click="uploadfile('filenya_sketsa', 'image/*')"
                                                            disabled="disabled">Upload
                                                            File</button>
                                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                                            @click="clearfilepeta('filenya_sketsa')"
                                                            disabled="disabled">Hapus File</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--IKON DENAH END-->
                                            <div class="col-sm-12" style="display: none;">&nbsp;</div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="scheduler-border">
                                        <legend class="scheduler-border">Lainnya</legend>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label class="labelgray">Latitude Rumah</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm"
                                                        id="latitude_rumah" name="latitude_rumah"
                                                        placeholder="Latitude Rumah" v-model="latitude_rumah"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Longitude Rumah</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm"
                                                        id="longitude_rumah" name="longitude_rumah"
                                                        placeholder="Longitude Rumah" v-model="longitude_rumah"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Latitude Lahan</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm"
                                                        id="latitude_lahan" name="latitude_lahan"
                                                        placeholder="Latitude Lahan" v-model="latitude_lahan"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Longitude Lahan</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control form-control-sm"
                                                        id="longitude_lahan" name="longitude_lahan"
                                                        placeholder="Longitude Lahan" v-model="longitude_lahan"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Tanggal Survey</label>
                                                <div class="input-group mb-3">
                                                    <input type="date" class="form-control form-control-sm"
                                                        id="tanggal_survey" name="tanggal_survey"
                                                        placeholder="Tanggal Survey" v-model="tanggal_survey"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Pendamping</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm"
                                                        id="pendamping" name="pendamping" placeholder="Pendamping"
                                                        v-model="pendamping" style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Nomor Telp Pendamping</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm"
                                                        id="telp_pendamping" name="telp_pendamping"
                                                        placeholder="Nomor Telp Pendamping" v-model="telp_pendamping"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Tim Lapangan 1</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm" id="tim1"
                                                        name="tim1" placeholder="Tim Lapangan 1" v-model="tim1"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Tim Lapangan 2</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm" id="tim2"
                                                        name="tim2" placeholder="Tim Lapangan 2" v-model="tim2"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Tim Lapangan 3</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm" id="tim3"
                                                        name="tim3" placeholder="Tim Lapangan 3" v-model="tim3"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Perangkat Kabupaten / Kota</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm"
                                                        id="perangkat_kabkot" name="perangkat_kabkot"
                                                        placeholder="Perangkat Kabupaten / Kota"
                                                        v-model="perangkat_kabkot" style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Perangkat Desa</label>
                                                <div class="input-group mb-3">
                                                    <input type="text" class="form-control form-control-sm"
                                                        id="perangkat_desa" name="perangkat_desa"
                                                        placeholder="Perangkat Desa" v-model="perangkat_desa"
                                                        style="background-color: #eef1f6;"
                                                        v-bind:class="{ disabled: true }">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Nilai Bantuan (Rp.)</label>
                                                <input type="number" class="form-control form-control-sm" id="nilai"
                                                    name="nilai" placeholder="Nilai Bantuan (Rp.)" v-model="nilai"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }">
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Tahapan</label>
                                                <v-select :options="tahapan" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedtahapan"
                                                    @update:modelValue="ontahapanChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="labelgray">Status Data</label>
                                                <v-select :options="statusdata" :reduce="(label) => label.code"
                                                    label="label" v-model="defaultSelectedstatusdata"
                                                    @update:modelValue="onstatusdataChange"
                                                    style="background-color: #eef1f6;"
                                                    v-bind:class="{ disabled: true }"></v-select>
                                            </div>
                                            <div class="col-sm-12">
                                                <label class="labelgray">Keterangan</label>
                                                <quill-editor v-model:value="keterangan"
                                                    style="height: 10vh;background-color: #eef1f6;color: #111;"
                                                    v-bind:class="{ disabled: true }"></quill-editor>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Tutup form.."><i class="fas fa-window-close"></i> Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            kabkotuser: JSON.parse(localStorage.getItem("bdlsistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            kabkot: [],
            datakabkot: [],
            defaultSelectedkabkot: {
                code: 0,
                label: 'Pilih Kabupaten / Kota',
                tag: 0,
            },
            selectedkabkot: 0,

            kecamatan: [],
            datakecamatan: [],
            defaultSelectedkecamatan: {
                code: 0,
                label: 'Pilih Kecamatan',
                tag: 0,
            },
            selectedkecamatan: 0,

            desa: [],
            datadesa: [],
            defaultSelecteddesa: {
                code: 0,
                label: 'Pilih Desa / Kelurahan',
                tag: 0,
            },
            selecteddesa: 0,

            statusdata: [],
            datastatusdata: [],
            defaultSelectedstatusdata: {
                code: 0,
                label: 'Pilih Status Data',
                tag: 0,
            },
            selectedstatusdata: 0,

            nama: '',
            nik: '',
            nokk: '',
            nobdt: '',
            usia: '',
            alamat: '',
            rt: '',
            rw: '',
            dukuh: '',

            statusbantuan: [],
            datastatusbantuan: [],
            defaultSelectedstatusbantuan: {
                code: 0,
                label: 'Pilih Status Bantuan',
                tag: 0,
            },
            selectedstatusbantuan: 0,
            pekerjaan: '',
            statusnikah: [],
            datastatusnikah: [],
            defaultSelectedstatusnikah: {
                code: 0,
                label: 'Pilih Status Perkawinan',
                tag: 0,
            },
            selectedstatusnikah: 0,
            anggota_keluarga: '',
            kesediaan_swadaya: [],
            datakesediaan_swadaya: [],
            defaultSelectedkesediaan_swadaya: {
                code: 0,
                label: 'Pilih Kesediaan Swadaya',
                tag: 0,
            },
            selectedkesediaan_swadaya: 0,

            jumlah_swadaya: [],
            datajumlah_swadaya: [],
            defaultSelectedjumlah_swadaya: {
                code: 0,
                label: 'Pilih Jumlah Swadaya',
                tag: 0,
            },
            selectedjumlah_swadaya: 0,

            penghasilan: [],
            datapenghasilan: [],
            defaultSelectedpenghasilan: {
                code: 0,
                label: 'Pilih Jumlah Penghasilan',
                tag: 0,
            },
            selectedpenghasilan: 0,

            nama_pemilik: '',
            nokk_pemilik: '',
            alamat_pemilik: '',

            status_tinggal: [],
            datastatus_tinggal: [],
            defaultSelectedstatus_tinggal: {
                code: 0,
                label: 'Pilih Status Tinggal',
                tag: 0,
            },
            selectedstatus_tinggal: 0,

            hubungan: [],
            datahubungan: [],
            defaultSelectedhubungan: {
                code: 0,
                label: 'Pilih Status Hubungan',
                tag: 0,
            },
            selectedhubungan: 0,

            jumlah_penghuni: '',
            jumlah_kk: '',

            alamat_lahan: '',
            panjang_lahan: '',
            lebar_lahan: '',

            status_perolehan: [],
            datastatus_perolehan: [],
            defaultSelectedstatus_perolehan: {
                code: 0,
                label: 'Pilih Status Perolehan',
                tag: 0,
            },
            selectedstatus_perolehan: 0,

            kondisi_lahan: [],
            datakondisi_lahan: [],
            defaultSelectedkondisi_lahan: {
                code: 0,
                label: 'Pilih Kondisi Lahan',
                tag: 0,
            },
            selectedkondisi_lahan: 0,

            sertifikat_lahan: [],
            datasertifikat_lahan: [],
            defaultSelectedsertifikat_lahan: {
                code: 0,
                label: 'Pilih Sertifikat Lahan',
                tag: 0,
            },
            selectedsertifikat_lahan: 0,

            jenis_bencana: [],
            datajenis_bencana: [],
            defaultSelectedjenis_bencana: {
                code: 0,
                label: 'Pilih Jenis Bencana',
                tag: 0,
            },
            selectedjenis_bencana: 0,
            tahunbencana: '',
            panjang_bangunan: 0,
            lebar_bangunan: 0,

            struktur_roboh: [],
            datastruktur_roboh: [],
            defaultSelectedstruktur_roboh: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedstruktur_roboh: 0,

            nonstruktur_roboh: [],
            datanonstruktur_roboh: [],
            defaultSelectednonstruktur_roboh: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectednonstruktur_roboh: 0,

            atap: [],
            dataatap: [],
            defaultSelectedatap: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedatap: 0,

            balok: [],
            databalok: [],
            defaultSelectedbalok: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedbalok: 0,

            kolom: [],
            datakolom: [],
            defaultSelectedkolom: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedkolom: 0,

            pondasi: [],
            datapondasi: [],
            defaultSelectedpondasi: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedpondasi: 0,

            dinding: [],
            datadinding: [],
            defaultSelecteddinding: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selecteddinding: 0,

            genteng: [],
            datagenteng: [],
            defaultSelectedgenteng: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedgenteng: 0,

            plafon: [],
            dataplafon: [],
            defaultSelectedplafon: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedplafon: 0,

            lantai: [],
            datalantai: [],
            defaultSelectedlantai: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedlantai: 0,

            pintu: [],
            datapintu: [],
            defaultSelectedpintu: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedpintu: 0,

            jendela: [],
            datajendela: [],
            defaultSelectedjendela: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedjendela: 0,

            filenya_ktp: '',
            filenya_kk_penerima: '',
            filenya_kk_pemilik: '',
            filenya_lahan: '',
            filenya_penerima_rumah: '',
            filenya_penerima_lahan: '',
            filenya_sketsa: '',

            filenya_atap: '',
            filenya_balok: '',
            filenya_kolom: '',
            filenya_pondasi: '',
            filenya_dinding: '',
            filenya_genteng: '',
            filenya_plafon: '',
            filenya_lantai: '',
            filenya_pintu: '',
            filenya_jendela: '',

            latitude_rumah: '',
            longitude_rumah: '',
            latitude_lahan: '',
            longitude_lahan: '',

            tanggal_survey: '',
            pendamping: '',
            telp_pendamping: '',
            tim1: '',
            tim2: '',
            tim3: '',
            perangkat_desa: '',
            perangkat_kabkot: '',
            keterangan: '',

            validasi: false,

            tahun: [],
            datatahun: [],
            defaultSelectedtahun: {
                code: new Date().getFullYear(),
                label: new Date().getFullYear(),
                tag: new Date().getFullYear(),
            },
            selectedtahun: new Date().getFullYear(),
            nilai: 0,

            tahapan: [],
            datatahapan: [],
            defaultSelectedtahapan: {
                code: 0,
                label: 'Pilih Tahapan',
                tag: 0,
            },
            selectedtahapan: 0,
        }
    },
    methods: {
        lihatlampiran(filenya, alamat) {
            this.halamanloading = true;
            document.getElementById('iframefile').src = "";
            //alert(process.env.VUE_APP_URL_API + foldernya + '?f=' + filenya);
            document.getElementById('iframefile').src = process.env.VUE_APP_URL_API + alamat + '?f=' + filenya;
            $('#costumModaldisclaimer').modal('show');
            this.halamanloading = false;
        },
        onkabkotChange(a) {
            try {
                this.selectedkabkot = this.kabkot[a].tag;
                this.ambilkecamatan();
                this.ambildesa();
            }
            catch (err) {
                return;
            }
        },
        onkecamatanChange(a) {
            try {
                this.selectedkecamatan = this.kecamatan[a].tag;
                this.ambildesa();
            }
            catch (err) {
                return;
            }
        },
        ondesaChange(a) {
            try {
                this.selecteddesa = this.desa[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ontahunChange(a) {
            try {
                this.selectedtahun = this.tahun[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstatusdataChange(a) {
            try {
                this.selectedstatusdata = this.statusdata[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstatusbantuanChange(a) {
            try {
                this.selectedstatusbantuan = this.statusbantuan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstatusnikahChange(a) {
            try {
                this.selectedstatusnikah = this.statusnikah[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkesediaan_swadayaChange(a) {
            try {
                this.selectedkesediaan_swadaya = this.kesediaan_swadaya[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onjumlah_swadayaChange(a) {
            try {
                this.selectedjumlah_swadaya = this.jumlah_swadaya[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onpenghasilanChange(a) {
            try {
                this.selectedpenghasilan = this.penghasilan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstatus_tinggalChange(a) {
            try {
                this.selectedstatus_tinggal = this.status_tinggal[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onhubunganChange(a) {
            try {
                this.selectedhubungan = this.hubungan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstatus_perolehanChange(a) {
            try {
                this.selectedstatus_perolehan = this.status_perolehan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkondisi_lahanChange(a) {
            try {
                this.selectedkondisi_lahan = this.kondisi_lahan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onsertifikat_lahanChange(a) {
            try {
                this.selectedsertifikat_lahan = this.sertifikat_lahan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        async ambilkecamatan() {
            this.halamanloading = true;
            var random = Math.random();
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var Paramkabkot = this.selectedkabkot;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            await mainAPI.get("parastapainnovationLokasi-GetKecamatan?KodeKabkot=" + Paramkabkot + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                        this.datakecamatan = Response.data.content.data;
                        this.datakecamatan.forEach((item) => {
                            this.kecamatan.push({
                                label: item.Nama,
                                code: this.datakecamatan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.kecamatan.unshift({
                            label: 'Pilih Kecamatan',
                            code: 0,
                            tag: 0,
                        });
                        this.defaultSelectedkecamatan = {
                            code: 0,
                            label: 'Pilih Kecamatan',
                            tag: 0,
                        };
                        this.selectedkecamatan = 0;
                        this.defaultSelecteddesa = {
                            code: 0,
                            label: 'Pilih Desa / Kelurahan',
                            tag: 0,
                        };
                        this.selecteddesa = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async ambildesa() {
            this.halamanloading = true;
            var random = Math.random();
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var Paramkabkot = this.selectedkabkot;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            var Paramkecamatan = this.selectedkecamatan;
            if ((Paramkecamatan == '0') || (Paramkecamatan == '')) {
                Paramkecamatan = '';
            }
            await mainAPI.get("parastapainnovationLokasi-GetDesa?KodeKabkot=" + Paramkabkot + "&KodeKecamatan=" + Paramkecamatan + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadesa = [];
                        this.desa = [];
                        this.datadesa = Response.data.content.data;
                        this.datadesa.forEach((item) => {
                            this.desa.push({
                                label: item.Nama,
                                code: this.datadesa.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.desa.unshift({
                            label: 'Pilih Desa / Kelurahan',
                            code: 0,
                            tag: 0,
                        });
                        this.defaultSelecteddesa = {
                            code: 0,
                            label: 'Pilih Desa / Kelurahan',
                            tag: 0,
                        };
                        this.selecteddesa = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        ontabelChange(a) {
            try {
                this.selectedtabel = this.tabel[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/PBPKRTKB");
        },
        uploadfile(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        clearfilepeta(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                    document.getElementById(idnya).value == '';
                    this.filenya_ktp = '';
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovationPBPKRTKB-GetIDDataPBPKRTKB?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.nama = Response.data.content.data[0].nama;
                    this.halamanloading = false;
                    this.halamanloading = true;
                    for (let i = 2010; i < 2041; i++) {
                        this.tahun.push({
                            label: i,
                            code: i,
                            tag: i,
                        });
                    }
                    this.defaultSelectedtahun = {
                        code: Response.data.content.data[0].tahun,
                        label: Response.data.content.data[0].tahun,
                        tag: Response.data.content.data[0].tahun,
                    };
                    this.selectedtahun = Response.data.content.data[0].tahun;
                    this.selectedkabkot = Response.data.content.data[0].kabkot;
                    this.selectedkecamatan = Response.data.content.data[0].kecamatan;
                    this.selecteddesa = Response.data.content.data[0].desa;

                    this.defaultSelectedkabkot = {
                        code: Response.data.content.data[0].kabkot,
                        label: Response.data.content.data[0].NMKabkot,
                        tag: Response.data.content.data[0].kabkot,
                    };

                    this.defaultSelectedkecamatan = {
                        code: Response.data.content.data[0].kecamatan,
                        label: Response.data.content.data[0].NMKECAMATAN,
                        tag: Response.data.content.data[0].kecamatan,
                    };

                    this.defaultSelecteddesa = {
                        code: Response.data.content.data[0].desa,
                        label: Response.data.content.data[0].NMDESA,
                        tag: Response.data.content.data[0].desa,
                    };

                    this.nik = Response.data.content.data[0].nik;
                    this.nokk = Response.data.content.data[0].nokk;
                    this.nobdt = Response.data.content.data[0].nobdt;
                    this.usia = Response.data.content.data[0].usia;
                    this.alamat = Response.data.content.data[0].alamat;
                    this.rt = Response.data.content.data[0].rt;
                    this.rw = Response.data.content.data[0].rw;
                    this.dukuh = Response.data.content.data[0].dukuh;

                    this.selectedstatusdata = Response.data.content.data[0].status_data;
                    this.defaultSelectedstatusdata = {
                        code: Response.data.content.data[0].status_data,
                        label: Response.data.content.data[0].NMSTATUSDATA,
                        tag: Response.data.content.data[0].status_data,
                    };

                    this.statusbantuan = Response.data.content.data[0].status_bantuan;
                    this.defaultSelectedstatusbantuan = {
                        code: Response.data.content.data[0].status_bantuan,
                        label: Response.data.content.data[0].NMSTATUSBANTUAN,
                        tag: Response.data.content.data[0].status_bantuan,
                    };

                    this.pekerjaan = Response.data.content.data[0].pekerjaan;
                    this.selectedstatusnikah = Response.data.content.data[0].status_nikah;
                    this.defaultSelectedstatusnikah = {
                        code: Response.data.content.data[0].status_nikah,
                        label: Response.data.content.data[0].NMSTATUSNIKAH,
                        tag: Response.data.content.data[0].status_nikah,
                    }

                    this.anggota_keluarga = Response.data.content.data[0].anggota_keluarga;
                    this.selectedkesediaan_swadaya = Response.data.content.data[0].kesediaan_swadaya;
                    this.defaultSelectedkesediaan_swadaya = {
                        code: Response.data.content.data[0].kesediaan_swadaya,
                        label: Response.data.content.data[0].NMSTATUSSWADAYA,
                        tag: Response.data.content.data[0].kesediaan_swadaya,
                    }

                    this.selectedjumlah_swadaya = Response.data.content.data[0].jumlah_swadaya;
                    this.defaultSelectedjumlah_swadaya = {
                        code: Response.data.content.data[0].jumlah_swadaya,
                        label: Response.data.content.data[0].NMJUMLAHSWADAYA,
                        tag: Response.data.content.data[0].jumlah_swadaya,
                    }

                    this.selectedpenghasilan = Response.data.content.data[0].penghasilan;
                    this.defaultSelectedpenghasilan = {
                        code: Response.data.content.data[0].penghasilan,
                        label: Response.data.content.data[0].NMPENGHASILAN,
                        tag: Response.data.content.data[0].penghasilan,
                    }

                    this.nama_pemilik = Response.data.content.data[0].nama_pemilik;
                    this.nokk_pemilik = Response.data.content.data[0].nokk_pemilik;
                    this.alamat_pemilik = Response.data.content.data[0].alamat_pemilik;

                    this.selectedstatus_tinggal = Response.data.content.data[0].status_tinggal;
                    this.defaultSelectedstatus_tinggal = {
                        code: Response.data.content.data[0].status_tinggal,
                        label: Response.data.content.data[0].NMSTATUSTINGGAL,
                        tag: Response.data.content.data[0].status_tinggal,
                    }

                    this.selectedhubungan = Response.data.content.data[0].hubungan;
                    this.defaultSelectedhubungan = {
                        code: Response.data.content.data[0].hubungan,
                        label: Response.data.content.data[0].NMHUBUNGAN,
                        tag: Response.data.content.data[0].hubungan,
                    }

                    this.jumlah_penghuni = Response.data.content.data[0].jumlah_penghuni;
                    this.jumlah_kk = Response.data.content.data[0].jumlah_kk;
                    this.alamat_lahan = Response.data.content.data[0].alamat_lahan;
                    this.panjang_lahan = Response.data.content.data[0].panjang_lahan;
                    this.lebar_lahan = Response.data.content.data[0].lebar_lahan;

                    this.selectedstatus_perolehan = Response.data.content.data[0].status_perolehan;
                    this.defaultSelectedstatus_perolehan = {
                        code: Response.data.content.data[0].status_perolehan,
                        label: Response.data.content.data[0].NMSTATUSPEROLEHAN,
                        tag: Response.data.content.data[0].status_perolehan,
                    }

                    this.selectedkondisi_lahan = Response.data.content.data[0].kondisi_lahan;
                    this.defaultSelectedkondisi_lahan = {
                        code: Response.data.content.data[0].kondisi_lahan,
                        label: Response.data.content.data[0].NMSTATUSLAHAN,
                        tag: Response.data.content.data[0].kondisi_lahan,
                    }

                    this.selectedsertifikat_lahan = Response.data.content.data[0].sertifikat_lahan;
                    this.defaultSelectedsertifikat_lahan = {
                        code: Response.data.content.data[0].sertifikat_lahan,
                        label: Response.data.content.data[0].NMSERTIFIKAT,
                        tag: Response.data.content.data[0].sertifikat_lahan,
                    }

                    this.selectedjenis_bencana = Response.data.content.data[0].jenis_bencana;
                    this.defaultSelectedjenis_bencana = {
                        code: Response.data.content.data[0].jenis_bencana,
                        label: Response.data.content.data[0].NMBENCANA,
                        tag: Response.data.content.data[0].jenis_bencana,
                    }

                    this.selectedstruktur_roboh = Response.data.content.data[0].struktur_roboh;
                    this.defaultSelectedstruktur_roboh = {
                        code: Response.data.content.data[0].struktur_roboh,
                        label: Response.data.content.data[0].NMSTRUKTUR,
                        tag: Response.data.content.data[0].struktur_roboh,
                    }

                    this.selectednonstruktur_roboh = Response.data.content.data[0].nonstruktur_roboh;
                    this.defaultSelectednonstruktur_roboh = {
                        code: Response.data.content.data[0].nonstruktur_roboh,
                        label: Response.data.content.data[0].NMNONSTRUKTUR,
                        tag: Response.data.content.data[0].nonstruktur_roboh,
                    }

                    this.selectedtingkat_kerusakan = Response.data.content.data[0].tingkat_kerusakan;
                    this.defaultSelectedtingkat_kerusakan = {
                        code: Response.data.content.data[0].tingkat_kerusakan,
                        label: Response.data.content.data[0].NMRUSAK,
                        tag: Response.data.content.data[0].tingkat_kerusakan,
                    }

                    this.selectedatap = Response.data.content.data[0].atap;
                    this.defaultSelectedatap = {
                        code: Response.data.content.data[0].atap,
                        label: Response.data.content.data[0].NMATAP,
                        tag: Response.data.content.data[0].atap,
                    }

                    this.selectedbalok = Response.data.content.data[0].balok;
                    this.defaultSelectedbalok = {
                        code: Response.data.content.data[0].balok,
                        label: Response.data.content.data[0].NMBALOK,
                        tag: Response.data.content.data[0].balok,
                    }

                    this.selectedkolom = Response.data.content.data[0].kolom;
                    this.defaultSelectedkolom = {
                        code: Response.data.content.data[0].kolom,
                        label: Response.data.content.data[0].NMKOLOM,
                        tag: Response.data.content.data[0].kolom,
                    }

                    this.selectedpondasi = Response.data.content.data[0].pondasi;
                    this.defaultSelectedpondasi = {
                        code: Response.data.content.data[0].pondasi,
                        label: Response.data.content.data[0].NMPONDASI,
                        tag: Response.data.content.data[0].pondasi,
                    }

                    this.selecteddinding = Response.data.content.data[0].dinding;
                    this.defaultSelecteddinding = {
                        code: Response.data.content.data[0].dinding,
                        label: Response.data.content.data[0].NMDINDING,
                        tag: Response.data.content.data[0].dinding,
                    }

                    this.selectedgenteng = Response.data.content.data[0].genteng;
                    this.defaultSelectedgenteng = {
                        code: Response.data.content.data[0].genteng,
                        label: Response.data.content.data[0].NMGENTENG,
                        tag: Response.data.content.data[0].genteng,
                    }

                    this.selectedplafon = Response.data.content.data[0].plafon;
                    this.defaultSelectedplafon = {
                        code: Response.data.content.data[0].plafon,
                        label: Response.data.content.data[0].NMPLAFON,
                        tag: Response.data.content.data[0].plafon,
                    }

                    this.selectedlantai = Response.data.content.data[0].lantai;
                    this.defaultSelectedlantai = {
                        code: Response.data.content.data[0].lantai,
                        label: Response.data.content.data[0].NMLANTAI,
                        tag: Response.data.content.data[0].lantai,
                    }

                    this.selectedpintu = Response.data.content.data[0].pintu;
                    this.defaultSelectedpintu = {
                        code: Response.data.content.data[0].pintu,
                        label: Response.data.content.data[0].NMPINTU,
                        tag: Response.data.content.data[0].pintu,
                    }

                    this.selectedjendela = Response.data.content.data[0].jendela;
                    this.defaultSelectedjendela = {
                        code: Response.data.content.data[0].jendela,
                        label: Response.data.content.data[0].NMJENDELA,
                        tag: Response.data.content.data[0].jendela,
                    }

                    this.tahunbencana = Response.data.content.data[0].tahunbencana;

                    document.getElementById('filenya_ktp').value = Response.data.content.data[0].filenya_ktp;
                    this.filenya_ktp = Response.data.content.data[0].filenya_ktp;
                    document.getElementById('filenya_kk_penerima').value = Response.data.content.data[0].filenya_kk_penerima;
                    this.filenya_kk_penerima = Response.data.content.data[0].filenya_kk_penerima;
                    document.getElementById('filenya_kk_pemilik').value = Response.data.content.data[0].filenya_kk_pemilik;
                    this.filenya_kk_pemilik = Response.data.content.data[0].filenya_kk_pemilik;
                    document.getElementById('filenya_lahan').value = Response.data.content.data[0].filenya_lahan;
                    this.filenya_lahan = Response.data.content.data[0].filenya_lahan;
                    document.getElementById('filenya_penerima_rumah').value = Response.data.content.data[0].filenya_penerima_rumah;
                    this.filenya_penerima_rumah = Response.data.content.data[0].filenya_penerima_rumah;
                    document.getElementById('filenya_penerima_lahan').value = Response.data.content.data[0].filenya_penerima_lahan;
                    this.filenya_penerima_lahan = Response.data.content.data[0].filenya_penerima_lahan;
                    document.getElementById('filenya_sketsa').value = Response.data.content.data[0].filenya_sketsa;
                    this.filenya_sketsa = Response.data.content.data[0].filenya_sketsa;

                    document.getElementById('filenya_atap').value = Response.data.content.data[0].filenya_atap;
                    this.filenya_atap = Response.data.content.data[0].filenya_atap;
                    document.getElementById('filenya_balok').value = Response.data.content.data[0].filenya_balok;
                    this.filenya_balok = Response.data.content.data[0].filenya_balok;
                    document.getElementById('filenya_kolom').value = Response.data.content.data[0].filenya_kolom;
                    this.filenya_kolom = Response.data.content.data[0].filenya_kolom;
                    document.getElementById('filenya_pondasi').value = Response.data.content.data[0].filenya_pondasi;
                    this.filenya_pondasi = Response.data.content.data[0].filenya_pondasi;
                    document.getElementById('filenya_dinding').value = Response.data.content.data[0].filenya_dinding;
                    this.filenya_dinding = Response.data.content.data[0].filenya_dinding;
                    document.getElementById('filenya_genteng').value = Response.data.content.data[0].filenya_genteng;
                    this.filenya_genteng = Response.data.content.data[0].filenya_genteng;
                    document.getElementById('filenya_plafon').value = Response.data.content.data[0].filenya_plafon;
                    this.filenya_plafon = Response.data.content.data[0].filenya_plafon;
                    document.getElementById('filenya_lantai').value = Response.data.content.data[0].filenya_lantai;
                    this.filenya_lantai = Response.data.content.data[0].filenya_lantai;
                    document.getElementById('filenya_pintu').value = Response.data.content.data[0].filenya_pintu;
                    this.filenya_pintu = Response.data.content.data[0].filenya_pintu;
                    document.getElementById('filenya_jendela').value = Response.data.content.data[0].filenya_jendela;
                    this.filenya_jendela = Response.data.content.data[0].filenya_jendela;

                    this.latitude_rumah = Response.data.content.data[0].latitude_rumah;
                    this.longitude_rumah = Response.data.content.data[0].longitude_rumah;
                    this.latitude_lahan = Response.data.content.data[0].latitude_lahan;
                    this.longitude_lahan = Response.data.content.data[0].longitude_lahan;
                    this.tanggal_survey = Response.data.content.data[0].tanggal_survey;
                    this.pendamping = Response.data.content.data[0].pendamping;
                    this.telp_pendamping = Response.data.content.data[0].telp_pendamping;
                    this.tim1 = Response.data.content.data[0].tim1;
                    this.tim2 = Response.data.content.data[0].tim2;
                    this.tim3 = Response.data.content.data[0].tim3;
                    this.perangkat_desa = Response.data.content.data[0].perangkat_desa;
                    this.perangkat_kabkot = Response.data.content.data[0].perangkat_kabkot;
                    this.keterangan = Response.data.content.data[0].keterangan;

                    this.panjang_bangunan = Response.data.content.data[0].panjang_bangunan;
                    this.lebar_bangunan = Response.data.content.data[0].lebar_bangunan;
                    this.nilai = Response.data.content.data[0].nilai;

                    this.selectedtahapan = Response.data.content.data[0].tahapan;
                    this.defaultSelectedtahapan = {
                        code: Response.data.content.data[0].tahapan,
                        label: Response.data.content.data[0].NMTAHAPAN,
                        tag: Response.data.content.data[0].tahapan,
                    }

                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })


            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            var Paramkabkot = this.kabkotuser;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            await mainAPI.get("parastapainnovationLokasi-GetLokasiNonKodeProvinsi?KodeKabkot=" + Paramkabkot + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakabkot = [];
                        this.kabkot = [];
                        this.datakabkot = Response.data.content.data;
                        this.datakabkot.forEach((item) => {
                            this.kabkot.push({
                                label: item.Nama,
                                code: this.datakabkot.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                            this.kabkot.unshift({
                                label: 'Pilih Kabupaten / Kota',
                                code: 0,
                                tag: 0,
                            });
                        }
                        else {
                            this.datakabkot.forEach((item) => {
                                this.defaultSelectedkabkot = {
                                    code: item.Kode,
                                    label: item.Nama,
                                    tag: item.Kode,
                                };
                            });

                        }
                    }

                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationLokasi-GetKecamatan?KodeKabkot=" + Paramkabkot + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                        this.datakecamatan = Response.data.content.data;
                        this.datakecamatan.forEach((item) => {
                            this.kecamatan.push({
                                label: item.Nama,
                                code: this.datakecamatan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.kecamatan.unshift({
                            label: 'Pilih Kecamatan',
                            code: 0,
                            tag: 0,
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationLokasi-GetDesa?KodeKabkot=" + Paramkabkot + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadesa = [];
                        this.desa = [];
                        this.datadesa = Response.data.content.data;
                        this.datadesa.forEach((item) => {
                            this.desa.push({
                                label: item.Nama,
                                code: this.datadesa.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.desa.unshift({
                            label: 'Pilih Desa / Kelurahan',
                            code: 0,
                            tag: 0,
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetStatusData?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastatusdata = [];
                        this.statusdata = [];
                        this.datastatusdata = Response.data.content.data;
                        this.datastatusdata.forEach((item) => {
                            this.statusdata.push({
                                label: item.Nama,
                                code: this.datastatusdata.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.statusdata.unshift({
                            label: 'Pilih Status Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetStatusBantuan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastatusbantuan = [];
                        this.statusbantuan = [];
                        this.datastatusbantuan = Response.data.content.data;
                        this.datastatusbantuan.forEach((item) => {
                            this.statusbantuan.push({
                                label: item.Nama,
                                code: this.datastatusbantuan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.statusbantuan.unshift({
                            label: 'Pilih Status Bantuan',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetStatusNikah?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastatusnikah = [];
                        this.statusnikah = [];
                        this.datastatusnikah = Response.data.content.data;
                        this.datastatusnikah.forEach((item) => {
                            this.statusnikah.push({
                                label: item.Nama,
                                code: this.datastatusnikah.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.statusnikah.unshift({
                            label: 'Pilih Status Perkawinan',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetKesediaanSwadaya?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakesediaan_swadaya = [];
                        this.kesediaan_swadaya = [];
                        this.datakesediaan_swadaya = Response.data.content.data;
                        this.datakesediaan_swadaya.forEach((item) => {
                            this.kesediaan_swadaya.push({
                                label: item.Nama,
                                code: this.datakesediaan_swadaya.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.kesediaan_swadaya.unshift({
                            label: 'Pilih Kesediaan Swadaya',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetJumlahSwadaya?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajumlah_swadaya = [];
                        this.jumlah_swadaya = [];
                        this.datajumlah_swadaya = Response.data.content.data;
                        this.datajumlah_swadaya.forEach((item) => {
                            this.jumlah_swadaya.push({
                                label: item.Nama,
                                code: this.datajumlah_swadaya.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.jumlah_swadaya.unshift({
                            label: 'Pilih Jumlah Swadaya',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetPenghasilan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datapenghasilan = [];
                        this.penghasilan = [];
                        this.datapenghasilan = Response.data.content.data;
                        this.datapenghasilan.forEach((item) => {
                            this.penghasilan.push({
                                label: item.Nama,
                                code: this.datapenghasilan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.penghasilan.unshift({
                            label: 'Pilih Jumlah Penghasilan',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetStatusTinggal?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastatus_tinggal = [];
                        this.status_tinggal = [];
                        this.datastatus_tinggal = Response.data.content.data;
                        this.datastatus_tinggal.forEach((item) => {
                            this.status_tinggal.push({
                                label: item.Nama,
                                code: this.datastatus_tinggal.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.status_tinggal.unshift({
                            label: 'Pilih Status Tinggal',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetStatusHubungan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datahubungan = [];
                        this.hubungan = [];
                        this.datahubungan = Response.data.content.data;
                        this.datahubungan.forEach((item) => {
                            this.hubungan.push({
                                label: item.Nama,
                                code: this.datahubungan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.hubungan.unshift({
                            label: 'Pilih Status Hubungan',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetPerolehanLahan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastatus_perolehan = [];
                        this.status_perolehan = [];
                        this.datastatus_perolehan = Response.data.content.data;
                        this.datastatus_perolehan.forEach((item) => {
                            this.status_perolehan.push({
                                label: item.Nama,
                                code: this.datastatus_perolehan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.status_perolehan.unshift({
                            label: 'Pilih Status Perolehan',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetKondisiLahan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakondisi_lahan = [];
                        this.kondisi_lahan = [];
                        this.datakondisi_lahan = Response.data.content.data;
                        this.datakondisi_lahan.forEach((item) => {
                            this.kondisi_lahan.push({
                                label: item.Nama,
                                code: this.datakondisi_lahan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.kondisi_lahan.unshift({
                            label: 'Pilih Kondisi Lahan',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetSertifikatLahan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datasertifikat_lahan = [];
                        this.sertifikat_lahan = [];
                        this.datasertifikat_lahan = Response.data.content.data;
                        this.datasertifikat_lahan.forEach((item) => {
                            this.sertifikat_lahan.push({
                                label: item.Nama,
                                code: this.datasertifikat_lahan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.sertifikat_lahan.unshift({
                            label: 'Pilih Sertifikat Lahan',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetTahapanPelaksanaan?random=" + random + "&jenis=PK").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatahapan = [];
                        this.tahapan = [];
                        this.datatahapan = Response.data.content.data;
                        this.datatahapan.forEach((item) => {
                            this.tahapan.push({
                                label: item.Nama,
                                code: this.datatahapan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.tahapan.unshift({
                            label: 'Pilih Tahapan',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.initialize();
        this.AmbilDataSingel();
    }
}
</script>
<style>
.disabled {
    pointer-events: none;
    color: #bfcbd9;
    cursor: not-allowed;
    background-image: none;
    background-color: #eef1f6;
    border-color: #d1dbe5;
}
</style>