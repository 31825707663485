<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <center>
            <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
                aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
                style="margin: auto;">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Detail Data Duplikasi NIK</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p>
                            <div class="col-sm-12">
                                <div class="table-responsive table-wrapper">
                                    <table class="table m-0" id="tabeldata">
                                        <thead>
                                            <tr>
                                                <th style="width: 30px;">No</th>
                                                <th style="width: 80px;">Kabupaten / Kota<br>Kecamatan<br>Desa /
                                                    Kelurahan</th>
                                                <th style="width: 80px;">Nama</th>
                                                <th style="width: 80px;">Alamat</th>
                                                <th style="width: 80px;">NIK</th>
                                                <th style="width: 80px;">ID BDT</th>
                                                <th style="width: 80px;">Tahapan</th>
                                            </tr>
                                        </thead>
                                        <template v-for="(datalist, urutlist) in datamasterduplikasi" :key="urutlist">
                                            <tbody>
                                                <tr>
                                                    <td>{{ urutlist + 1 }}</td>
                                                    <td>
                                                        {{ datalist.NMKabkot }}<br>{{ datalist.NMKECAMATAN }}<br>{{
                                                            datalist.NMDESA }}
                                                    </td>
                                                    <td>
                                                        {{ datalist.nama }}
                                                    </td>
                                                    <td>
                                                        {{ removeHTMLTags(datalist.alamat) }}
                                                    </td>
                                                    <td>
                                                        {{ datalist.nik }}
                                                    </td>
                                                    <td>
                                                        {{ datalist.nobdt }}
                                                    </td>
                                                    <td class="text-right">
                                                        {{ datalist.NamaTahapan }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                        <tbody v-if="(datapesan == 'data kosong')">
                                            <tr>
                                                <td colspan="8">Data masih kosong</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </p>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-danger btn-sm lebar" data-dismiss="modal"><i
                                    class="fas fa-window-close"></i>
                                Tutup</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- /.modal -->
        </center>
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12 wow fadeInDown">
                            <h5>Hello, Welcome {{ namauser }}</h5>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div id="dataheader">
                                    <div class="card-tools float-right">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                            @click="toggleShow">
                                            <i :class="{ 'fas fa-minus': showFilter, 'fas fa-plus': !showFilter }"
                                                style="color: aliceblue;"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-2">
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Tahun Data</label>
                                        <v-select :options="tahun" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedtahun" @update:modelValue="ontahunChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Kab./Kota</label>
                                        <v-select :options="kabkot" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedkabkot" @update:modelValue="onkabkotChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Kecamatan</label>
                                        <v-select :options="kecamatan" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedkecamatan" @update:modelValue="onkecamatanChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Desa / Kelurahan</label>
                                        <v-select :options="desa" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelecteddesa" @update:modelValue="ondesaChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Opsi</label><br>
                                        <button type="button" class="btn btn-primary btn-sm text-white lebar2"
                                            @click="caridata()" data-toggle="tooltip" data-placement="left"
                                            title="Cari data..">
                                            <i class="fas fa-search"></i> Cari Data
                                        </button>
                                    </div>

                                </div>
                                <div class="card-footer p-3">
                                    <div class="col-sm-12">
                                        <div class="cardputih">
                                            <!-- Add the bg color to the header using any of the bg-* classes -->
                                            <div class="card-header">
                                                <h3 class="card-title">Total Pengajuan Tahun</h3>
                                            </div>
                                            <BarChartPengajuan height="350" :options="chartOptionsBarChartPengajuan"
                                                :series="seriesBarChartPengajuan">
                                            </BarChartPengajuan>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 wow fadeInUp">
                                    <div class="card cardputih">
                                        <div class="card-header border-transparent">
                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabeldata">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 10px;" rowspan="2">No</th>
                                                            <th style="width: 80px;" rowspan="2">Jenis Bantuan</th>
                                                            <th style="width: 80px;" rowspan="2">Jumlah<br>Usulan</th>
                                                            <th style="width: 80px;" rowspan="2">
                                                                Jumlah<br>Verval<br>Awal</th>
                                                            <th style="width: 80px;" rowspan="2">
                                                                Jumlah<br>Verval<br>Lanjutan
                                                            </th>
                                                            <th style="width: 80px;" rowspan="2">Jumlah<br>e Rembugan
                                                            </th>
                                                            <th style="width: 80px;" rowspan="2">Jumlah<br>Alokasi</th>
                                                            <th style="width: 80px;" rowspan="2">
                                                                Jumlah<br>Verval<br>Final</th>
                                                            <th style="width: 80px;" colspan="3">Jumlah<br>Pelaksanaan
                                                            </th>
                                                            <th style="width: 80px;" rowspan="2">Total<br>Data</th>
                                                            <th style="width: 80px;" rowspan="2">
                                                                Jumlah<br>Duplikasi<br>NIK</th>
                                                        </tr>
                                                        <tr>
                                                            <th style="width: 80px;">Belum<br>Terlaksana</th>
                                                            <th style="width: 80px;">Sudah<br>Terlaksana</th>
                                                            <th style="width: 80px;">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datalist, urutlist) in datamaster"
                                                        :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center">{{ urutlist + 1 }}</td>
                                                                <td class="text-left">{{ datalist.nama }}</td>
                                                                <td class="levelparentkategoribelum text-center">{{
                                                                    G_numFormat(datalist.JmlUsulan) }}</td>
                                                                <td class="levelsubsubkegiatan text-center">{{
                                                                    G_numFormat(datalist.JmlVervalAwal) }}</td>
                                                                <td class="levelkomponen levelpaket text-center">{{
                                                                    G_numFormat(datalist.JmlVervalLanjutan) }}</td>
                                                                <td class="leveldetailpekerjaan text-center">{{
                                                                    G_numFormat(datalist.JmleRembugan) }}</td>
                                                                <td class="levelsubkomponen text-center">{{
                                                                    G_numFormat(datalist.JmlAlokasi) }}</td>
                                                                <td class="levelkomponen text-center">{{
                                                                    G_numFormat(datalist.JmlVervalFinal) }}</td>
                                                                <td class="levelsubkegiatan text-center">{{
                                                                    G_numFormat(datalist.JmlPelaksanaanBelumProsesTerlaksana)
                                                                    }}</td>
                                                                <td class="levelparentkategorisudah text-center">{{
                                                                    G_numFormat(datalist.JmlPelaksanaanSudahProsesTerlaksana)
                                                                    }}</td>
                                                                <td class="levelparentkategori text-center">{{
                                                                    G_numFormat(datalist.JmlPelaksanaan) }}</td>
                                                                <td class="levelkegiatan text-center">{{
                                                                    G_numFormat(datalist.Total) }}</td>
                                                                <td class="levellewat text-center">
                                                                    <a href="javascript:void(0)"
                                                                        @click="modal(datalist.unixcode)">
                                                                        {{ G_numFormat(datalist.JmlDuplikasi) }}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                    <tfoot>
                                                        <tr>
                                                            <td class="leveltotal text-center" colspan="2">TOTAL</td>
                                                            <td class="leveltotal text-center">{{
                                                                G_numFormat(JmlUsulan) }}</td>
                                                            <td class="leveltotal text-center">{{
                                                                G_numFormat(JmlVervalAwal) }}</td>
                                                            <td class="leveltotal levelpaket text-center">{{
                                                                G_numFormat(JmlVervalLanjutan) }}</td>
                                                            <td class="leveltotal text-center">{{
                                                                G_numFormat(JmleRembugan) }}</td>
                                                            <td class="leveltotal text-center">{{
                                                                G_numFormat(JmlAlokasi) }}</td>
                                                            <td class="leveltotal text-center">{{
                                                                G_numFormat(JmlVervalFinal) }}</td>
                                                            <td class="leveltotal text-center">{{
                                                                G_numFormat(JmlPelaksanaanBelumProsesTerlaksana) }}</td>
                                                            <td class="leveltotal text-center">{{
                                                                G_numFormat(JmlPelaksanaanSudahProsesTerlaksana) }}</td>
                                                            <td class="leveltotal text-center">{{
                                                                G_numFormat(JmlPelaksanaan) }}</td>
                                                            <td class="leveltotal text-center">{{
                                                                G_numFormat(Total) }}</td>
                                                            <td class="leveltotal text-center">
                                                                {{ G_numFormat(JmlDuplikasi) }}
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, swal, BarChartPengajuan: VueApexCharts
    },
    data() {
        return {
            halamanloading: true,
            showFilter: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            kabkotuser: JSON.parse(localStorage.getItem("bdlsistem")),
            datapesan: '',
            datamaster: [],
            datamasterduplikasi: [],
            carikata: '',
            kabkot: [],
            datakabkot: [],
            defaultSelectedkabkot: {
                code: 0,
                label: 'Semua Kabupaten / Kota',
                tag: 0,
            },
            selectedkabkot: 0,

            kecamatan: [],
            datakecamatan: [],
            defaultSelectedkecamatan: {
                code: 0,
                label: 'Semua Kecamatan',
                tag: 0,
            },
            selectedkecamatan: 0,

            desa: [],
            datadesa: [],
            defaultSelecteddesa: {
                code: 0,
                label: 'Semua Desa / Kelurahan',
                tag: 0,
            },
            selecteddesa: 0,

            statusdata: [],
            datastatusdata: [],
            defaultSelectedstatusdata: {
                code: 0,
                label: 'Semua Status Data',
                tag: 0,
            },
            selectedstatusdata: 0,

            tahun: [],
            datatahun: [],
            defaultSelectedtahun: {
                code: 0,
                label: 'Seluruh Tahun',
                tag: '',
            },
            selectedtahun: '',
            Total: 0,
            JmlUsulan: 0,
            JmlVervalAwal: 0,
            JmlVervalLanjutan: 0,
            JmleRembugan: 0,
            JmlAlokasi: 0,
            JmlVervalFinal: 0,
            JmlPelaksanaanBelumProsesTerlaksana: 0,
            JmlPelaksanaanSudahProsesTerlaksana: 0,
            JmlPelaksanaan: 0,
            JmlDuplikasi: 0,
            chartOptionsBarChartPengajuan: {
                chart: {
                    type: 'bar',
                    height: 250,
                    stacked: true,
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                dataLabels: {
                    formatter: (val) => {
                        return val
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                xaxis: {
                    categories: [
                        'PB RUMAH SEDERHANA SEHAT',
                        'PB RELOKASI PROGRAM PEMERINTAH',
                        'PB RUMAH KORBAN BENCANA',
                        'PK RUMAH KORBAN BENCANA',
                    ],
                    labels: {
                        style: {
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontFamily: 'Poppins-SemiBold, Arial',
                            color: '#111'
                        },
                        formatter: (val) => {
                            return val
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                colors: ['#80c7fd', '#008FFB', '#FFD700', '#00E396', '#9ACD32', '#FF6347', '#006400', '#00008B', '#00FFFF', '#FF7F50', '#D2691E'],
                yaxis: {
                    show: false,
                    labels: {
                        style: {
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontFamily: 'Poppins-SemiBold, Arial',
                            color: '#111'
                        },
                        formatter: (val) => {
                            return val;
                        }
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: 'Poppins-SemiBold, Arial',
                        color: '#111'
                    },
                }
            },
            seriesBarChartPengajuan: [],

        }
    },
    methods: {
        modal(kode) {
            this.halamanloading = true;
            var Paramkabkot = this.selectedkabkot;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            var Paramkecamatan = this.selectedkecamatan;
            if ((Paramkecamatan == '0') || (Paramkecamatan == '')) {
                Paramkecamatan = '';
            }
            var Paramdesa = this.selecteddesa;
            if ((Paramdesa == '0') || (Paramdesa == '')) {
                Paramdesa = '';
            }
            var Paramstatusdata = this.selectedstatusdata;
            if ((Paramstatusdata == '0') || (Paramstatusdata == '')) {
                Paramstatusdata = '';
            }
            var Paramstahun = this.selectedtahun;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPI.get("parastapainnovation-DuplikasiNIK?random=" + random + "&Tahun=" + Paramstahun + "&KodeKabkot=" + Paramkabkot + "&KodeKecamatan=" + Paramkecamatan + "&KodeDesa=" + Paramdesa + "&tabel=" + kode).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamasterduplikasi = [];
                        this.datamasterduplikasi = Response.data.content.data;
                        $('#costumModaldisclaimer').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.datamasterduplikasi = [];
                        swal.fire('Peringatan', 'Data Kosong', 'error');
                        this.halamanloading = false;
                        return false;
                    }

                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

        },
        toggleShow() {
            this.showFilter = !this.showFilter;
        },
        onkabkotChange(a) {
            try {
                this.selectedkabkot = this.kabkot[a].tag;
                this.ambilkecamatan();
                this.ambildesa();
            }
            catch (err) {
                return;
            }
        },
        onkecamatanChange(a) {
            try {
                this.selectedkecamatan = this.kecamatan[a].tag;
                this.ambildesa();
            }
            catch (err) {
                return;
            }
        },
        ondesaChange(a) {
            try {
                this.selecteddesa = this.desa[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstatusdataChange(a) {
            try {
                this.selectedstatusdata = this.statusdata[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ontahunChange(a) {
            try {
                this.selectedtahun = this.tahun[a].tag;
            }
            catch (err) {
                return;
            }
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        async ambilkecamatan() {
            this.halamanloading = true;
            var random = Math.random();
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var Paramkabkot = this.selectedkabkot;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            await mainAPI.get("parastapainnovationLokasi-GetKecamatan?KodeKabkot=" + Paramkabkot + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                        this.datakecamatan = Response.data.content.data;
                        this.datakecamatan.forEach((item) => {
                            this.kecamatan.push({
                                label: item.Nama,
                                code: this.datakecamatan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.kecamatan.unshift({
                            label: 'Semua Kecamatan',
                            code: 0,
                            tag: 0,
                        });
                        this.defaultSelectedkecamatan = {
                            code: 0,
                            label: 'Pilih Kecamatan',
                            tag: 0,
                        };
                        this.selectedkecamatan = 0;
                        this.defaultSelecteddesa = {
                            code: 0,
                            label: 'Pilih Desa / Kelurahan',
                            tag: 0,
                        };
                        this.selecteddesa = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async ambildesa() {
            this.halamanloading = true;
            var random = Math.random();
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var Paramkabkot = this.selectedkabkot;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            var Paramkecamatan = this.selectedkecamatan;
            if ((Paramkecamatan == '0') || (Paramkecamatan == '')) {
                Paramkecamatan = '';
            }
            await mainAPI.get("parastapainnovationLokasi-GetDesa?KodeKabkot=" + Paramkabkot + "&KodeKecamatan=" + Paramkecamatan + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadesa = [];
                        this.desa = [];
                        this.datadesa = Response.data.content.data;
                        this.datadesa.forEach((item) => {
                            this.desa.push({
                                label: item.Nama,
                                code: this.datadesa.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.desa.unshift({
                            label: 'Semua Desa / Kelurahan',
                            code: 0,
                            tag: 0,
                        });
                        this.defaultSelecteddesa = {
                            code: 0,
                            label: 'Pilih Desa / Kelurahan',
                            tag: 0,
                        };
                        this.selecteddesa = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPISETTING = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPISETTING.get("parastapainnovation_setting-Data?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.tutupdata = Response.data.content.data[0].Nama;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            var Paramkabkot = this.kabkotuser;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            await mainAPI.get("parastapainnovationLokasi-GetLokasiNonKodeProvinsi?KodeKabkot=" + Paramkabkot + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakabkot = [];
                        this.kabkot = [];
                        this.datakabkot = Response.data.content.data;
                        this.datakabkot.forEach((item) => {
                            this.kabkot.push({
                                label: item.Nama,
                                code: this.datakabkot.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                            this.kabkot.unshift({
                                label: 'Semua Kabupaten / Kota',
                                code: 0,
                                tag: 0,
                            });
                            this.selectedkabkot = 0;
                        }
                        else {
                            this.datakabkot.forEach((item) => {
                                this.defaultSelectedkabkot = {
                                    code: item.Kode,
                                    label: item.Nama,
                                    tag: item.Kode,
                                };
                            });
                            this.selectedkabkot = this.kabkotuser;
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationLokasi-GetKecamatan?KodeKabkot=" + Paramkabkot + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                        this.datakecamatan = Response.data.content.data;
                        this.datakecamatan.forEach((item) => {
                            this.kecamatan.push({
                                label: item.Nama,
                                code: this.datakecamatan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.kecamatan.unshift({
                            label: 'Semua Kecamatan',
                            code: 0,
                            tag: 0,
                        });
                        this.selectedkecamatan = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationLokasi-GetDesa?KodeKabkot=" + Paramkabkot + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadesa = [];
                        this.desa = [];
                        this.datadesa = Response.data.content.data;
                        this.datadesa.forEach((item) => {
                            this.desa.push({
                                label: item.Nama,
                                code: this.datadesa.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.desa.unshift({
                            label: 'Semua Desa / Kelurahan',
                            code: 0,
                            tag: 0,
                        });
                        this.selecteddesa = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetStatusData?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastatusdata = [];
                        this.statusdata = [];
                        this.datastatusdata = Response.data.content.data;
                        this.datastatusdata.forEach((item) => {
                            this.statusdata.push({
                                label: item.Nama,
                                code: this.datastatusdata.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.statusdata.unshift({
                            label: 'Semua Status Data',
                            code: 0,
                            tag: 0,
                        });
                        this.selectedstatusdata = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationPBRSS-GetTahunData?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatahun = [];
                        this.tahun = [];
                        this.datatahun = Response.data.content.data;
                        this.datatahun.forEach((item) => {
                            this.tahun.push({
                                label: item.Tahun,
                                code: this.datatahun.indexOf(item) + 1,
                                tag: item.Tahun,
                            });
                        });
                        this.tahun.unshift({
                            code: 0,
                            label: 'Seluruh Tahun',
                            tag: '',
                        });

                        this.selectedtahun = this.selectedtahun;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            //this.halamanloading = true;
            var Paramkabkot = this.selectedkabkot;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            var Paramkecamatan = this.selectedkecamatan;
            if ((Paramkecamatan == '0') || (Paramkecamatan == '')) {
                Paramkecamatan = '';
            }
            var Paramdesa = this.selecteddesa;
            if ((Paramdesa == '0') || (Paramdesa == '')) {
                Paramdesa = '';
            }
            var Paramstatusdata = this.selectedstatusdata;
            if ((Paramstatusdata == '0') || (Paramstatusdata == '')) {
                Paramstatusdata = '';
            }
            var Paramstahun = this.selectedtahun;
            await mainAPI.get("parastapainnovation-Dashboard?random=" + random + "&Tahun=" + Paramstahun + "&KodeKabkot=" + Paramkabkot + "&KodeKecamatan=" + Paramkecamatan + "&KodeDesa=" + Paramdesa + "&StatusData=" + Paramstatusdata).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.seriesBarChartPengajuan = [];
                        this.Total = Response.data.content.data.reduce((a, { Total: e }) => a + e, 0);
                        this.JmlUsulan = Response.data.content.data.reduce((a, { JmlUsulan: e }) => a + e, 0);
                        this.JmlVervalAwal = Response.data.content.data.reduce((a, { JmlVervalAwal: e }) => a + e, 0);
                        this.JmlVervalLanjutan = Response.data.content.data.reduce((a, { JmlVervalLanjutan: e }) => a + e, 0);
                        this.JmleRembugan = Response.data.content.data.reduce((a, { JmleRembugan: e }) => a + e, 0);
                        this.JmlAlokasi = Response.data.content.data.reduce((a, { JmlAlokasi: e }) => a + e, 0);
                        this.JmlVervalFinal = Response.data.content.data.reduce((a, { JmlVervalFinal: e }) => a + e, 0);
                        this.JmlPelaksanaanBelumProsesTerlaksana = Response.data.content.data.reduce((a, { JmlPelaksanaanBelumProsesTerlaksana: e }) => a + e, 0);
                        this.JmlPelaksanaanSudahProsesTerlaksana = Response.data.content.data.reduce((a, { JmlPelaksanaanSudahProsesTerlaksana: e }) => a + e, 0);
                        this.JmlPelaksanaan = Response.data.content.data.reduce((a, { JmlPelaksanaan: e }) => a + e, 0);
                        this.JmlDuplikasi = Response.data.content.data.reduce((a, { JmlDuplikasi: e }) => a + e, 0);
                        this.selectedtahun = this.selectedtahun;

                        if ((this.Total) == 0) {
                            this.seriesBarChartPengajuan = [];
                        }
                        else {
                            var arrayusulan = [];
                            var arrayvervalawal = [];
                            var arrayvervallanjutan = [];
                            var arrayerembugan = [];
                            var arrayalokasi = [];
                            var arrayvervalfinal = [];
                            var arraybelum = [];
                            var arraysudah = [];
                            this.datamaster.forEach((item) => {
                                arrayusulan.push(item.JmlUsulan);
                                arrayvervalawal.push(item.JmlVervalAwal);
                                arrayvervallanjutan.push(item.JmlVervalLanjutan);
                                arrayerembugan.push(item.JmleRembugan);
                                arrayalokasi.push(item.JmlAlokasi);
                                arrayvervalfinal.push(item.JmlVervalFinal);
                                arraybelum.push(item.JmlPelaksanaanBelumProsesTerlaksana);
                                arraysudah.push(item.JmlPelaksanaanSudahProsesTerlaksana);
                            });

                            this.seriesBarChartPengajuan = [
                                {
                                    name: 'Usulan',
                                    group: 'JumlahData',
                                    data: arrayusulan
                                },
                                {
                                    name: 'Verval Awal',
                                    group: 'JumlahData',
                                    data: arrayvervalawal
                                },
                                {
                                    name: 'Verval Lanjutan',
                                    group: 'JumlahData',
                                    data: arrayvervallanjutan
                                },
                                {
                                    name: 'e Rembugan',
                                    group: 'JumlahData',
                                    data: arrayerembugan
                                },
                                {
                                    name: 'Alokasi',
                                    group: 'JumlahData',
                                    data: arrayalokasi
                                },
                                {
                                    name: 'Verval Final',
                                    group: 'JumlahData',
                                    data: arrayvervalfinal
                                },
                                {
                                    name: 'Belum Terlaksana',
                                    group: 'JumlahData',
                                    data: arraybelum
                                },
                                {
                                    name: 'Sudah Terlaksana',
                                    group: 'JumlahData',
                                    data: arraysudah
                                },

                            ];
                        }
                    }
                    else {
                        this.datamaster = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })


        },
        async caridata() {
            //this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            var Paramkabkot = this.selectedkabkot;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            var Paramkecamatan = this.selectedkecamatan;
            if ((Paramkecamatan == '0') || (Paramkecamatan == '')) {
                Paramkecamatan = '';
            }
            var Paramdesa = this.selecteddesa;
            if ((Paramdesa == '0') || (Paramdesa == '')) {
                Paramdesa = '';
            }
            var Paramstatusdata = this.selectedstatusdata;
            if ((Paramstatusdata == '0') || (Paramstatusdata == '')) {
                Paramstatusdata = '';
            }
            var Paramstahun = this.selectedtahun;
            await mainAPI.get("parastapainnovation-Dashboard?random=" + random + "&Tahun=" + Paramstahun + "&KodeKabkot=" + Paramkabkot + "&KodeKecamatan=" + Paramkecamatan + "&KodeDesa=" + Paramdesa + "&StatusData=" + Paramstatusdata).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.Total = Response.data.content.data.reduce((a, { Total: e }) => a + e, 0);
                        this.JmlUsulan = Response.data.content.data.reduce((a, { JmlUsulan: e }) => a + e, 0);
                        this.JmlVervalAwal = Response.data.content.data.reduce((a, { JmlVervalAwal: e }) => a + e, 0);
                        this.JmlVervalLanjutan = Response.data.content.data.reduce((a, { JmlVervalLanjutan: e }) => a + e, 0);
                        this.JmleRembugan = Response.data.content.data.reduce((a, { JmleRembugan: e }) => a + e, 0);
                        this.JmlAlokasi = Response.data.content.data.reduce((a, { JmlAlokasi: e }) => a + e, 0);
                        this.JmlVervalFinal = Response.data.content.data.reduce((a, { JmlVervalFinal: e }) => a + e, 0);
                        this.JmlPelaksanaanBelumProsesTerlaksana = Response.data.content.data.reduce((a, { JmlPelaksanaanBelumProsesTerlaksana: e }) => a + e, 0);
                        this.JmlPelaksanaanSudahProsesTerlaksana = Response.data.content.data.reduce((a, { JmlPelaksanaanSudahProsesTerlaksana: e }) => a + e, 0);
                        this.JmlPelaksanaan = Response.data.content.data.reduce((a, { JmlPelaksanaan: e }) => a + e, 0);
                        this.JmlDuplikasi = Response.data.content.data.reduce((a, { JmlDuplikasi: e }) => a + e, 0);
                        this.selectedtahun = this.selectedtahun;
                        if ((this.Total) == 0) {
                            this.seriesBarChartPengajuan = [];
                        }
                        else {
                            var arrayusulan = [];
                            var arrayvervalawal = [];
                            var arrayvervallanjutan = [];
                            var arrayerembugan = [];
                            var arrayalokasi = [];
                            var arrayvervalfinal = [];
                            var arraybelum = [];
                            var arraysudah = [];
                            this.datamaster.forEach((item) => {
                                arrayusulan.push(item.JmlUsulan);
                                arrayvervalawal.push(item.JmlVervalAwal);
                                arrayvervallanjutan.push(item.JmlVervalLanjutan);
                                arrayerembugan.push(item.JmleRembugan);
                                arrayalokasi.push(item.JmlAlokasi);
                                arrayvervalfinal.push(item.JmlVervalFinal);
                                arraybelum.push(item.JmlPelaksanaanBelumProsesTerlaksana);
                                arraysudah.push(item.JmlPelaksanaanSudahProsesTerlaksana);
                            });

                            this.seriesBarChartPengajuan = [
                                {
                                    name: 'Usulan',
                                    group: 'JumlahData',
                                    data: arrayusulan
                                },
                                {
                                    name: 'Verval Awal',
                                    group: 'JumlahData',
                                    data: arrayvervalawal
                                },
                                {
                                    name: 'Verval Lanjutan',
                                    group: 'JumlahData',
                                    data: arrayvervallanjutan
                                },
                                {
                                    name: 'e Rembugan',
                                    group: 'JumlahData',
                                    data: arrayerembugan
                                },
                                {
                                    name: 'Alokasi',
                                    group: 'JumlahData',
                                    data: arrayalokasi
                                },
                                {
                                    name: 'Verval Final',
                                    group: 'JumlahData',
                                    data: arrayvervalfinal
                                },
                                {
                                    name: 'Belum Terlaksana',
                                    group: 'JumlahData',
                                    data: arraybelum
                                },
                                {
                                    name: 'Sudah Terlaksana',
                                    group: 'JumlahData',
                                    data: arraysudah
                                },

                            ];
                        }
                    }
                    else {
                        this.datamaster = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildata();
    }
}
</script>
<style></style>