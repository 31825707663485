<template>
  <div class="content" v-if="route.name == 'Beranda'">
    <Beranda/>
  </div>
  <div class="content" v-if="route.name == 'Login'">
    <Login/>
  </div>
  <div class="content" v-if="route.name == 'KontakKami'">
    <KontakKami/>
  </div>
  <div class="content" v-if="route.name == 'NSWI'">
    <NSWI/>
  </div>
  <div class="content" v-if="route.name == 'GIS'">
    <GIS/>
  </div>
  <div class="content" v-if="route.name == 'DaftarRegulasi'">
    <DaftarRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'Dashboard'">
    <Dashboard/>
  </div>
  <div class="content" v-if="route.name == 'Slider'">
    <Slider/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSlider'">
    <ProsesSlider/>
  </div>
  <div class="content" v-if="route.name == 'InfoBeranda'">
    <InfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'ProsesInfoBeranda'">
    <ProsesInfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'KategoriData'">
    <KategoriData/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategoriData'">
    <ProsesKategoriData/>
  </div>
  <div class="content" v-if="route.name == 'LayerPeta'">
    <LayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLayerPeta'">
    <ProsesLayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'User'">
    <User/>
  </div>
  <div class="content" v-if="route.name == 'ProsesUser'">
    <ProsesUser/>
  </div>
  <div class="content" v-if="route.name == 'PBRSS'">
    <PBRSS/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPBRSS'">
    <ProsesPBRSS/>
  </div>
  <div class="content" v-if="route.name == 'ViewPBRSS'">
    <ViewPBRSS/>
  </div>
  <div class="content" v-if="route.name == 'PBRRP'">
    <PBRRP/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPBRRP'">
    <ProsesPBRRP/>
  </div>
  <div class="content" v-if="route.name == 'ViewPBRRP'">
    <ViewPBRRP/>
  </div>
  <div class="content" v-if="route.name == 'PBRTKB'">
    <PBRTKB/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPBRTKB'">
    <ProsesPBRTKB/>
  </div>
  <div class="content" v-if="route.name == 'ViewPBRTKB'">
    <ViewPBRTKB/>
  </div>
  <div class="content" v-if="route.name == 'PBPKRTKB'">
    <PBPKRTKB/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPBPKRTKB'">
    <ProsesPBPKRTKB/>
  </div>
  <div class="content" v-if="route.name == 'ViewPBPKRTKB'">
    <ViewPBPKRTKB/>
  </div>
  <div class="content" v-if="route.name == 'Regulasi'">
    <Regulasi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesRegulasi'">
    <ProsesRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'SettingData'">
    <SettingData/>
  </div>
</template>

<script>
import Beranda from './components/views/Publik/Beranda.vue';
import Login from './components/views/Publik/Login.vue';
import KontakKami from './components/views/Publik/KontakKami.vue';
import GIS from './components/views/Publik/GIS.vue';
import DaftarRegulasi from './components/views/Publik/DaftarRegulasi.vue';
import Dashboard from './components/views/Dashboard/Dashboard.vue';
import Slider from './components/views/Backoffice/Slider/Slider.vue';
import ProsesSlider from './components/views/Backoffice/Slider/ProsesSlider.vue';
import InfoBeranda from './components/views/Backoffice/InfoBeranda/InfoBeranda.vue';
import ProsesInfoBeranda from './components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue';
import KategoriData from './components/views/Backoffice/KategoriData/KategoriData.vue';
import ProsesKategoriData from './components/views/Backoffice/KategoriData/ProsesKategoriData.vue';
import LayerPeta from './components/views/Backoffice/LayerPeta/LayerPeta.vue';
import ProsesLayerPeta from './components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue';
import Regulasi from './components/views/Backoffice/Regulasi/Regulasi.vue';
import ProsesRegulasi from './components/views/Backoffice/Regulasi/ProsesRegulasi.vue';
import SettingData from './components/views/Backoffice/SettingData/SettingData.vue';
import User from './components/views/Backoffice/User/User.vue';
import ProsesUser from './components/views/Backoffice/User/ProsesUser.vue';
import PBRSS from './components/views/Backoffice/PBRSS/PBRSS.vue';
import ProsesPBRSS from './components/views/Backoffice/PBRSS/ProsesPBRSS.vue';
import ViewPBRSS from './components/views/Backoffice/PBRSS/ViewPBRSS.vue';
import PBRRP from './components/views/Backoffice/PBRRP/PBRRP.vue';
import ProsesPBRRP from './components/views/Backoffice/PBRRP/ProsesPBRRP.vue';
import ViewPBRRP from './components/views/Backoffice/PBRRP/ViewPBRRP.vue';
import PBRTKB from './components/views/Backoffice/PBRTKB/PBRTKB.vue';
import ProsesPBRTKB from './components/views/Backoffice/PBRTKB/ProsesPBRTKB.vue';
import ViewPBRTKB from './components/views/Backoffice/PBRTKB/ViewPBRTKB.vue';
import PBPKRTKB from './components/views/Backoffice/PBPKRTKB/PBPKRTKB.vue';
import ProsesPBPKRTKB from './components/views/Backoffice/PBPKRTKB/ProsesPBPKRTKB.vue';
import ViewPBPKRTKB from './components/views/Backoffice/PBPKRTKB/ViewPBPKRTKB.vue';

import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    Beranda,Login,KontakKami,GIS,DaftarRegulasi,Dashboard
    ,Slider,ProsesSlider,InfoBeranda,ProsesInfoBeranda,SettingData
    ,KategoriData,ProsesKategoriData,LayerPeta,ProsesLayerPeta
    ,User,ProsesUser,PBRSS,ProsesPBRSS,ViewPBRSS
    ,PBRRP,ProsesPBRRP,ViewPBRRP
    ,PBRTKB,ProsesPBRTKB,ViewPBRTKB
    ,PBPKRTKB,ProsesPBPKRTKB,ViewPBPKRTKB
    ,Regulasi,ProsesRegulasi
  },
  mounted() {
    document.title = "BerandaNgumah";
  },
  setup() {
    const route = useRoute()
    watch(() => route.name, () => {
    });

    return { route };
  }
}
</script>

<style></style>
